import { useState } from "react";
import c from "./styles.module.scss";
import Hoverable from "../../hoverable";

const Quantity = (props) => {
	const {
		value = 1,
		min = 1,
		max = 50,
		onChange,
		size = "normal",
		quantity,
	} = props;
	const [dynamicValue, setDynamicValue] = useState(value);

	const setValue = (value) => {
		value = Math.max(Math.min(Number(value) || min, max), min);

		setDynamicValue(value);

		if (onChange) {
			onChange(value);
		}
	};

	const disabled = dynamicValue >= quantity;
	return (
		<Hoverable>
			<div className={[c.quantity, c[size]].join(" ")}>
				<button  className={c.btn} onClick={() => setValue(dynamicValue - 1)}>
					-
				</button>
				<input
					disabled={disabled}
					className={c.input}
                    max={quantity}
					value={dynamicValue}
					onChange={(e) => setDynamicValue(e.target.value)}
					onBlur={(e) => setValue(e.target.value)}
				/>
				<button disabled={disabled} className={c.btn} onClick={() => setValue(dynamicValue + 1)}>
					+
				</button>
			</div>
		</Hoverable>
	);
};

export default Quantity;
