import React from "react";
import c from "./styles.module.scss";
import Button from "../UI/button";
import { useNavigate } from "react-router-dom";

const PostItem = ({ post }) => {
	const { slug, title, metadata } = post;
	const navigate = useNavigate();

	const truncateText = (text, length) => {
		if (!text) return "Описание отсутствует.";
		return text.length > length ? text.slice(0, length) + "..." : text;
	};

	return (
		<article className={c.post}>
			<div className={c.inner}>
				<div
					className={c.image}
					style={{
						backgroundImage: `url(${
							metadata?.["post-mobile-image"]?.["imgix_url"] ||
							"/default-image.jpg"
						})`,
					}}
				></div>
				<div className={c.brief}>
					<h3 className={c.title}>{title}</h3>
					<p className={c.description}>
						{truncateText(metadata?.["post-seo-description"], 200)}
					</p>
					<Button
						text="Читать"
						afterIcon="arrow-right"
						onClick={() => navigate(`/blog/${slug}`)}
					/>
				</div>
			</div>
		</article>
	);
};

export default PostItem;
