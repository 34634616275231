import c from './styles.module.scss'
import CloseBtn from '../UI/closeBtn'
import EmptyBlock from '../emptyBlock'
import { quantityFormate } from '../../utils/quantity'
import { API_URL } from '../../http'
import { useSelector } from 'react-redux'
import CartController from '../../controllers/cartController'
import Quantity from '../UI/quantity'
import { NavLink } from 'react-router-dom'
import Hoverable from '../hoverable'
import Button from '../UI/button'

const CartProductsItem = ({ data }) => {
    const { id, name, quantity, slug, image, productQuantity } = data
    let timer

    return (
        <div className={c.item}>
            <div className={c.info}>
                <NavLink to={'/products/' + id} end className={c.name}>
                    <div className={c.image}>
                        {image ? <img src={API_URL + image} alt={name} /> : <span className={[c.icon, 'icon-image'].join(' ')}></span>}
                    </div>
                    <div className={c.text}>
                        <div className={c.title}>{name}</div>
                        <div className={c.subtitle}>Артикул: {slug}</div>
                    </div>
                </NavLink>
                <div className={c.quantity}>
                    <Quantity
                        size="small"
                        value={quantity}
                        quantity={productQuantity}
                        onChange={quantity => {
                            if(timer) {
                                timer = clearTimeout(timer)
                            }

                            timer = setTimeout(() => {
                                CartController.setQuantity(id, quantity)
                            }, 500)
                        }}
                    />
                </div>
                <Hoverable>
                    <div
                        className={[c.removeItem, 'icon-close'].join(' ')}
                        onClick={() => {
                            CartController.removeItem(id)
                        }}
                    ></div>
                </Hoverable>
            </div>
        </div>
    )
}

const CartDropdown = ({onClose}) => {
    const { added, products, totalQuantity } = useSelector(store => store.cart)
    let data = []

    for (let item of added) {
        const { id, quantity } = item

        if (products[id]) {
            const product = products[id]
            data.push({ ...product, quantity, price: product.price * quantity, productQuantity: product.quantity })
        }
    }

    return (
        <div className={c.dropdown}>
            <div className={c.header}>
                <div className={c.title}>Моя корзина <span>({quantityFormate(totalQuantity)})</span></div>
                { onClose ? <CloseBtn onClick={onClose}/> : null }
            </div>
            <div className={c.body}>
                {
                    data.length ?
                        data.map(item => <CartProductsItem key={item.id} data={item} />)
                        : <EmptyBlock text="Корзина пуста" size="small"/>
                }
            </div>
            {
                data.length ?
                    <div className={c.btns}>
                        <div className={c.btn}>
                            <Button text="Оформить заказ" size="smaller" full={true} link="/cart"/>
                        </div>
                        <div className={c.btn}>
                            <Button text="Очистить корзину" btnStyle="grey" size="smaller" full={true} onClick={CartController.clearCart}/>
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}

export default CartDropdown