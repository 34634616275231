import c from "./styles.module.scss";
import Container from "../container";
import Breadcrumbs from "../breadcrumbs";
import { NavLink } from "react-router-dom";
import { priceFormate } from "../../utils/price";
import Button from "../UI/button";
import Quantity from "../UI/quantity";
import ParamsList from "../paramsList";
import { useState, useEffect } from "react";
import { INFO_LINKS, FILTER_GROUPS } from "../../config";
import { API_URL } from "../../http";
import CartController from "../../controllers/cartController";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import BnplDetails from "./bnplDetails";

const PriceBlock = ({ id, price, quantity, data }) => {
  const navigate = useNavigate();
  const [count, setCount] = useState(1);
  const [showBNPLInfo, setShowBNPLInfo] = useState(false);

  const notifyItemAdded = async () => {
    try {
      const isItemInCart = CartController.isItemInCart(id);

      if (isItemInCart) {
        // Item is already in the cart
        toast.info("Товар уже в корзине", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      } else {
        // Item is not in the cart, add it
        await CartController.addToCart(id, count);

        toast.success("Товар добавлен в корзину", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
          onClick: () => {
            navigate("/cart");
          },
        });
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);

      toast.error("Произошла ошибка при добавлении товара в корзину", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const getBNPLInfo = () => {
    setShowBNPLInfo(true);
  };

  const oldPrice = price / (1 - 30 / 100);

  return (
		<>
			<BnplDetails
				showBNPLInfo={showBNPLInfo}
				setShowBNPLInfo={setShowBNPLInfo}
			/>
			<div className={c.priceBlock}>
				<div className={c.title}>Стоимость за единицу</div>
				<div className={c.price}>{priceFormate(Math.round(price))}</div>
				<div
					style={{
						display: "flex",
						gap: "2rem",
						alignItems: "center",
						marginBottom: "2rem",
					}}
				>
					<div className={c.oldPrice}>{priceFormate(oldPrice)}</div>
					<div className={c.discount}>30%</div>
				</div>
				{quantity >= 1 ? (
					<>
						<div className={c.fields}>
							<div className={c.quantity}>
								<Quantity onChange={setCount} value={count} quantity={quantity} size="smaller" />
							</div>

							<Button
								/* icon="cart" */
								text="Добавить в корзину"
								size="smaller"
								onClick={notifyItemAdded}
							/>
						</div>
						<div className={c.installment} onClick={getBNPLInfo}>
							<span className={c.mainTitle}>Рассрочка BNPL 0-0-4</span>
							<span className={c.furtherTitle}>Подробнее</span>
						</div>
					</>
				) : (
					<Button text="Нет в наличии" full={true} disabled />
				)}
			</div>
		</>
	);
};

const ProductInfo = ({ data }) => {
  const options = {
    id: data.id,
    image: data.image ? API_URL + data.image : null,
    title: data.name,
    brand: data.brand,
    vendor: data.slug,
    quantity: data.quantity,
    price: data.price,
    params: {},
    paramsElem: [],
  };

  if (data.attributes.length) {
    for (let item of data.attributes) {
      options.params[item.filterId] = item;
    }

    for (let filterGroup of FILTER_GROUPS) {
      const { name, items } = filterGroup;
      let params = [];

      for (let item of items) {
        if (options.params[item]) {
          params.push(options.params[item]);
        }
      }

      if (params.length) {
        options.paramsElem.push(
          <div className={c.item} key={name}>
            <div className={c.subtitle}>{name}</div>
            <ParamsList items={params} />
          </div>
        );
      }
    }
  }

  const [links] = useState(INFO_LINKS);

  const [style, setStyle] = useState("mobile");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setStyle("mobile");
      } else {
        setStyle("desktop");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  /* eslint-disable no-undef */
  const loadKaspiButtonScript = () => {
    if (!document.getElementById("KS-Widget")) {
      const script = document.createElement("script");
      script.id = "KS-Widget";
      script.src = "https://kaspi.kz/kaspibutton/widget/ks-wi_ext.js";
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    loadKaspiButtonScript();
  }, []);

  useEffect(() => {
    const addKaspiButton = () => {
      setTimeout(() => {
        const dynamicElement = document.getElementById("dynamic");
        if (dynamicElement && options.quantity >= 1) {
					dynamicElement.innerHTML = `<div class="ks-widget"  data-merchant-sku="${data.fullSlug}" data-merchant-code="Casio" data-city="750000000" data-template="flatButton" data-style="desktop"></div>`;
					console.log(dynamicElement.innerHTML);
					if (
						typeof ksWidgetInitializer !== "undefined" &&
						ksWidgetInitializer.reinit
					) {
						ksWidgetInitializer.reinit();
					}
				}
      }, 1000);
    };

    addKaspiButton();
  }, []);
  /* eslint-enable no-undef */

  return (
    <section className={c.section}>
      <Container>
        <div className={c.inner}>
          <div className={c.image}>
            {options.image ? (
              <div className={c.imageWrapper}>
                <div
                  className={c.blurImage}
                  style={{ backgroundImage: `url(${options.image})` }}
                ></div>
                <img src={options.image} alt={options.title} />
              </div>
            ) : (
              <div className={[c.noImage, "icon-image"].join(" ")}></div>
            )}
          </div>
          <div className={c.main}>
            <div className={c.header}>
              <Breadcrumbs
                path={[
                  {
                    link: "/products",
                    text: "Каталог",
                  },
                  {
                    link: "/",
                    text: options.title,
                  },
                ]}
              />
              <h1 className={c.title}>{options.title}</h1>
              <div className={c.meta}>
                <div className={c.line}>
                  {options.brand ? (
                    <div className={c.item}>
                      <span className={c.text}>Категория:</span>
                      <NavLink to={options.brand.link} end className={c.value}>
                        {options.brand.name}
                      </NavLink>
                    </div>
                  ) : null}
                  <div className={c.item}>
                    <span className={c.text}>Артикул:</span>
                    <span className={c.value}>{options.vendor}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={c.mainPriceBlock}>
              <PriceBlock
                id={options.id}
                price={options.price}
                quantity={options.quantity}
                data={data}
              />
              {(style === "mobile" ) && (
                <div style={{ marginTop: "2rem" }} id="dynamic"></div>
              )}
            </div>

            <div className={c.info}>
              <div className={["h6", c.title].join(" ")}>Характеристики</div>
              {options.paramsElem}
            </div>
          </div>

          <div className={c.aside}>
            <div className={c.sidebarPriceBlock}>
              <PriceBlock
                id={options.id}
                price={options.price}
                quantity={options.quantity}
              />

              {style === "desktop" && (
                <div style={{ marginTop: "2rem" }} id="dynamic"></div>
              )}
            </div>
            <div className={c.infoItems}>
              <div className={c.item}>
                <div id="dynamic"></div>
                <div className={[c.icon, "icon-success"].join(" ")}></div>
                <div className={c.text}>
                  В наличии: {options.quantity >= 1 ? "Есть" : "Нет"}
                </div>
              </div>
              {links.map((link, index) => (
                <NavLink to={link.link} end key={index} className={c.item}>
                  <div
                    className={[c.icon, "icon-" + link.icon].join(" ")}
                  ></div>
                  <div className={c.text}>{link.text}</div>
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ProductInfo;
