import c from "./styles.module.scss";
import { NavLink } from "react-router-dom";
import { priceFormate } from "../../utils/price";
import { API_URL } from "../../http";

const Product = ({ data }) => {
	const options = {
		image: data.image ? API_URL + data.image : null,
		title: data.name,
		price: data.price,
		oldPrice: 0,
		link: `/products/${data.id}`,
		quantity: data.quantity,
		classes: [c.product],
	};

	if (options.image) {
		options.image = (
			<>
				{/* <div
                    className={c.blur}
                    style={{
                        backgroundImage: `url(${options.image})`
                    }}
                ></div> */}
				<div className={c.image}>
					<img src={options.image} alt={options.title} loading="lazy" />
				</div>
			</>
		);
	} else {
		options.image = (
			<div className={c.image}>
				<div className={[c.noImage, "icon-image"].join(" ")}></div>
			</div>
		);
	}

	const oldPrice = options.price / (1 - 30 / 100);

	return (
		<NavLink
			to={options.link}
			end
			className={options.classes.join(" ")}
			style={{ position: "relative" }}
		>
			{options.image}
			<div className={c.content}>
				<div className={c.title}>{options.title}</div>
				<div className={c.prices}>
					{options.quantity >= 1 ? (
						<>
							<div className={c.price}>{priceFormate(options.price)}</div>
							<div className={c.oldPrice}>{priceFormate(oldPrice)}</div>
						
						</>
					) : (
						<p className={c.noStock}>Нет в наличии</p>
					)}
				</div>
			</div>
			<div className={c.badges}>
				<div className={c.discount}>30%</div>
				<div className={c.installment}>0-0-4</div>
			</div>
		</NavLink>
	);
};

export default Product;
